.ResearchPartner{
    padding: 48px 120px;
    gap: 24px;
    /* height: 200px; */
    background: var(--color-white-blue);
    box-sizing: border-box;
}
.ResearchPartnerSlideContainer{
    position: relative;
}
.ResearchPartner .slideImage{
    /* max-height: 101px; */
    /* width: 'auto'; */
    height: 101px;
    width: 100%;
    object-fit: contain;
}
.ResearchPartner .slide{
    height: 101px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ResearchPartnerSlideContainer .custom-prev, .ResearchPartnerSlideContainer .custom-next{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 40px;
    height: 40px;
    background: var(--color-white-light-blue);
    border-radius: 100px;
    border: none;
    cursor: pointer;
    z-index: 100;
}
.ResearchPartnerSlideContainer .custom-prev{
    left: 10px;
}
.ResearchPartnerSlideContainer .custom-next{
    right: 10px;
}
.ResearchPartner .flex-img-container{
    height: 101px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ResearchPartner .flex-img{
    width: 100%;
    height: auto;
    max-height: 101px;
    object-fit: contain;
}


@media only screen and (max-width: 991px) {
    .ResearchPartner{
        padding: 0px 24px 56px 24px;
    }
}
