.SilentWarning {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 120px;
    box-sizing: border-box;
    gap: 48px;
    height: 700px;
    background: url('../../assets/images/humanbrain.jpg');
    background-size: cover;
}

.SilentWarningDescription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 374px;
    height: 112px;
}

.SilentWarningDescription span {
    height: 40px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1px;
    color: #D2E4FF;
}

.SilentWarningDescription p {
    height: 48px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #D2E4FF;
    margin-top: auto;
}

.SilentWarningIconic {
    width: 100%;
    height: 316;
    justify-content: space-between;
    display: flex;
}

.SilentWarningIconic_Section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 24px;
    height: 316px;
}
.SilentWarningIconic_Section_Right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 24px;
}

.SilentWarningIconic .iconicCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 16px;
    max-width: 387px;
    box-sizing: border-box;
    background: rgba(0, 28, 55, 0.8);
    border-radius: 16px;
}

.SilentWarningIconic .iconicCard .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 56px;
    height: 56px;
    background: var(--color-bg-light-blue);
    border-radius: 16px;
}
.SilentWarningIconic .iconicCard .detail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 44px;
}
.SilentWarningIconic .iconicCard .detail h6{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: var(--color-white);
    margin: 0;
    margin-bottom: 10px;
}
.SilentWarningIconic .iconicCard .detail p{
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--color-white);
    margin: 0;
    margin-top: auto;
}

@media only screen and (max-width: 991px) {
    .SilentWarningIconic{
        gap: 24px;
    }
    .SilentWarning{
        height: 756px;
        padding: 56px 24px;
    }
    .SilentWarningIconic_Section_Right{
        align-items: flex-start;
    }
    .SilentWarningDescription{
        width: 100%;
    }
}
