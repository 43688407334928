.ScentAwareTestKits {
    padding: 48px 0;
    height: 624px;
    background: var(--color-white-blue);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.ScentAwareTestKits a{
    color: inherit;
}
.ScentAwareTestKitsInside{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    height: 528px;
}
.ScentAwareTestKits_header {
    padding: 0px 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    height: 48px;
}

.ScentAwareTestKits_header_left {
    /* width: 656px; */
    height: 40px;
    gap: 24px;
}

.ScentAwareTestKits_header_left h2 {
    width: 312px;
    height: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1px;
    color: var(--color-black);
    margin: 0;
}

.ScentAwareTestKits_header .ScentAwareTestKits_header_left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ScentAwareTestKits_header_left .line {
    padding: 0px;
    width: 320px;
    height: 0px;
    width: 320;
    border: 1px solid #CAC4D0;
}

.ScentAwareTestKits_header_right {
    display: flex;
    gap: 16px;
}

.ScentAwareTestKits_header_right .custom-prev,
.ScentAwareTestKits_header_right .custom-next {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 40px;
    height: 40px;
    background: var(--color-white-light-blue);
    border-radius: 100px;
    border: none;
    cursor: pointer;
    z-index: 100;
}

.ScentAwareTestKits_slider {
    display: flex;
    align-items: flex-start;
    padding-left: 120px;
    gap: 24px;
    height: 384px;
    position: relative;
}

.ScentAwareTestKits_slider .ScentAwareTestKits_slider_card {
    display: flex;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    isolation: isolate;
    width: 532px;
    height: 384px;
    border-radius: 24px;
    background-size: cover !important;
    position: relative;
}



.ScentAwareTestKits_slider .ScentAwareTestKits_slider_card .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 8px;
    position: absolute;
    width: 532px;
    height: 112px;
    left: 0px;
    bottom: 0;
    background: rgba(241, 242, 246, 0.8);
    backdrop-filter: blur(8px);
    border-radius: 0px 0px 24px 24px;
    box-sizing: border-box;
}

.ScentAwareTestKits_slider .ScentAwareTestKits_slider_card .title {
    height: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-second-dark-blue);
}

.ScentAwareTestKits_slider .ScentAwareTestKits_slider_card p {
    width: 484px;
    height: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--color-second-dark-blue);
    margin: 0;
}

.custom-pagination {
    text-align: center;
    margin-top: 20px;  
    display: flex;
    gap: 16px;

}
.custom-pagination-bullet {
    width: 16px;
    height: 16px;
    background: #DFE2EB;
    display: block;
    border-radius: 50%;
    cursor: pointer;
}
.custom-pagination-bullet-active {
    background-color: #37618E;
}


.ScentAwareTestKits_slider .slide{
    display: flex;
    /* justify-content: center; */
}
.paginationContainer{
    padding: 0px 120px;
}



.ScentAwareTestKits_card{
    display: flex;
    align-items: flex-start;
    gap: 10px;
    isolation: isolate;
    height: 384px;
    border-radius: 24px;
    background-size: cover !important;
    position: relative;
    background-position: 70% !important;
}

.ScentAwareTestKits_card .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 8px;
    position: absolute;
    width: 100%;
    height: 112px;
    left: 0px;
    bottom: 0;
    background: rgba(241, 242, 246, 0.8);
    backdrop-filter: blur(8px);
    border-radius: 0px 0px 24px 24px;
    box-sizing: border-box;
}

.ScentAwareTestKits_slider .ScentAwareTestKits_card .title {
    height: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-second-dark-blue);
}

.ScentAwareTestKits_slider .ScentAwareTestKits_card p {
    width: 484px;
    height: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--color-second-dark-blue);
    margin: 0;
}

@media only screen and (max-width: 991px) {
    .ScentAwareTestKits{
        padding: 56px 24px;
        height: auto;
    }
    .ScentAwareTestKits_header_left h2{
        font-size: 28px;
    }
    .ScentAwareTestKits_slider{
        display: none;
    }
    .ScentAwareTestKits_header{
        padding: 0;
    }
    .ScentAwareTestKits_header_right{
        display: none;
    }
    .ScentAwareTestKits_header .line{
        display: none;
    }
    .paginationContainer, .custom-pagination{
        display: none;
    }
}