.ProductForResearchersBanner_container {
  background-color: var(--color-white-light-blue);
}
.ProductForResearchersBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 120px;
  gap: 24px;
  height: 500px;
  background: #003259;
  border-radius: 0px 0px 0px 96px;
}

.ProductForResearchersBanner h1 {
  width: 690px;
  font-weight: 300;
  font-size: 45px;
  line-height: 52px;
  letter-spacing: -1px;
  color: #d2e4ff;
}

.ProductForResearchersBanner p {
  width: 673px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #d2e4ff;
}
@media only screen and (max-width: 991px) {
    .ProductForResearchersBanner{
        height: 800px;
        padding: 56px 24px;
        border-radius: 0px 0px 0px 64px;
    }
    .ProductForResearchersBanner h1{
        font-size: 36px;
        width: auto;
        padding-top: 56px;
    }
    .ProductForResearchersBanner p{
        width: auto;
    }
}