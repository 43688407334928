.LateDiagnosis{
    display: flex;
    justify-content: space-between;
    padding: 0 120px;
    gap: 24px;
    isolation: isolate;
    height: 500px;
    background: #111418;
}

.LateDiagnosisContent{
    /* width: 546px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
}
.LateDiagnosisContent span{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: var(--color-white-blue);
}

.LateDiagnosisContent p{
    width: 432px;
    height: 120px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--color-white-blue);
}
.LateDiagnosisImg{
    position: relative;
}
.LateDiagnosisImg .part1{
    position: absolute;
    width: 188px;
    height: 188px;
    right: 0;
    top: 0px;
    background: var(--color-blue);
    border-radius: 0px 94px;
    transform: rotate(90deg);
}
.LateDiagnosisImg .part2{
    position: absolute;
    width: 270px;
    height: 500px;
    right: 188px;
    top: 0px;
    background: #041940;
    border-radius: 0px 96px 135px 135px;
}
.LateDiagnosisImg .part2 .img{
    position: absolute;
    width: 270.13px;
    height: 482px;
    top: 18px;
    background: url('../../assets/images/sad.png');
    border-radius: 135.066px;
    background-size: cover;
}

.LateDiagnosisImg .part3{
    position: absolute;
    width: 188px;
    height: 188px;
    right: 458px;
    top: 0px;
    background: var(--color-blue);
    border-radius: 0px 94px;
    transform: rotate(90deg);
}


@media only screen and (max-width: 1199px) {
    .LateDiagnosisContent p{
        width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .LateDiagnosis{
        padding: 0px 24px;
        height: 600px;
    }
    .LateDiagnosisContent span{
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0px;
    }
}
  
  