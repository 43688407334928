.MetabolicDiseasesContainer {
  background-color: var(--color-dark-blue);
}
.MetabolicDiseases {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding);
  gap: 48px;
  isolation: isolate;
  height: 350px;
  background: var(--color-second-light-blue);
  border-radius: 0px 198px 0px 0px;
}
.MetabolicDiseases .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-dark-blue);
}
.MetabolicDiseases p {
  margin: 0 auto;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #001e2b;
}

@media only screen and (max-width: 991px) {
    .MetabolicDiseases{
        padding: 56px 24px;
        height: auto;
        border-radius: 0px 64px 0px 0px;
    }
    .MetabolicDiseases .heading{
        font-size: 28px;
    }
    .MetabolicDiseases p{
        font-size: 16px;
    }
}