.ConcordanceOfScentAwareContainer {
  height: 724px;
  background-color: var(--color-second-light-blue);
}
.ConcordanceOfScentAware {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-large);
  gap: 24px;
  isolation: isolate;
  height: 724px;
  background: var(--color-dark-blue);
  border-radius: 0px 0px 0px 198px;
}
.ConcordanceOfScentAwareRow {
  justify-content: space-between;
}
.ConcordanceOfScentAware_right {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ConcordanceOfScentAware_right img {
  width: 100%;
  /* height: 100%; */
}
.ConcordanceOfScentAware_left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}
.ConcordanceOfScentAware_left .heading {
  width: 390px;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-white);
}
.ConcordanceOfScentAware_left p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--color-white);
  margin: 0;
}
.ConcordanceOfScentAware_left h6 {
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: var(--color-white);
  margin: 0;
}
.ConcordanceOfScentAware_left ul {
  color: var(--color-white);
}
.ConcordanceOfScentAware_right p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--color-white);
}
@media only screen and (max-width: 991px) {
  .ConcordanceOfScentAware {
    padding: 56px 24px;
    border-radius: 0px 0px 0px 64px;
  }
  .ConcordanceOfScentAwareContainer {
    height: auto;
  }
  .ConcordanceOfScentAware_left .heading {
    width: auto;
    font-size: 28px;
  }
  .ConcordanceOfScentAware {
    height: auto;
  }
  .ConcordanceOfScentAwareRow {
    gap: 48px;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
  }
}
