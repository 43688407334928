.ForResearchers {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--padding);
    min-height: 400px;
    background: var(--color-dark-blue);
    box-sizing: border-box;
}

.ForResearchers_left {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.ForResearchers_left h2 {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1px;
    color: #D2E4FF;
}

.ForResearchers_left p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #D2E4FF;
}

.ForResearchers_left .LearnMore {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 146px;
    height: 40px;
    border: 1px solid #8D9199;
    border-radius: 100px;
    background-color: transparent;
    color: #A1C9FD;
}

.ForResearchers_right {
    height: 272px;
    background: url('../../assets/images/ForResearchers.png');
    border-radius: 16px;
    background-size: cover;
    background-position: center;
}

@media only screen and (max-width: 768px) {
    .ForResearchers{
        height: auto;
        flex-direction: column-reverse !important;
        padding: 56px 24px;
        gap: 48px !important;
    }
}