.Individuals {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--padding);
    min-height: 400px;
    background: #D2E4FF;
    box-sizing: border-box;
}

.Individuals_right {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.Individuals_right h2 {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1px;
    color: var(--color-dark-blue);
}

.Individuals_right p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--color-dark-blue);
}

.Individuals_right .LearnMore {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 146px;
    height: 40px;
    border: 1px solid #73777F;
    border-radius: 100px;
    background-color: transparent;
    color: #37618E;
}

.Individuals_left {
    height: 272px;
    background: url('../../assets/images/Individuals.png');
    border-radius: 16px;
    background-size: cover;
    background-position: center;
}


@media only screen and (max-width: 768px) {
    .Individuals{
        height: auto;
        flex-direction: column !important;
        padding: 56px 24px;
        gap: 48px !important;
    }
}