.RenAwareUrine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 120px;
  gap: 16px;
  height: 600px;
  background: var(--color-second-light-blue);
}
.RenAwareUrine .RenAwareUrine_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  margin: 0 auto;
  width: 486px;
  height: 200px;
}
.RenAwareUrine .RenAwareUrine_left .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-second-dark-blue);
}
.RenAwareUrine .RenAwareUrine_left p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--color-second-dark-blue);
  margin: 0;
}
.RenAwareUrine .RenAwareUrine_left button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  gap: 8px;
  height: 40px;
  background: var(--color-white-light-blue);
  border-radius: 100px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #001e2b;
}
.RenAwareUrine .RenAwareUrine_right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.RenAwareUrine .RenAwareUrine_right img {
  /* width: 100%; */
  max-height: 100%;
  max-width: 100%;
}

.modelcloseIcon {
  position: absolute;
  right: -25px;
  top: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.contactPopup {
  height: auto;
  width: 100%;
}
.contactPopup .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-second-dark-blue);
}

.contactPopup p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--color-black);
}
.contactPopupLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}
.contactPopupRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}
.contactPopupRight .form_group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.form_group label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--color-black);
}
.form_group input,
.form_group textarea {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  height: 56px;
  width: 100%;
  border: 1px solid var(--color-second-dark-blue);
  border-radius: 8px;
  background-color: white;
}
.form_group input:focus,
.form_group textarea:focus {
  outline: none;
}
.form_group textarea {
  height: 96px;
}
.contactPopupRight button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: 40px;
  width: 100%;
  background: #37618e;
  border-radius: 100px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: var(--color-white);
}
.custom-dialog .modal-body{
  /* overflow: auto; */
}
.modal-backdrop {
  text-align: center;
}
.modelcloseIconWhite{
  display: none;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
@media only screen and (max-width: 991px) {
  .RenAwareUrine {
    padding: 56px 24px;
    flex-direction: column-reverse;
    height: auto;
  }
  .RenAwareUrine .RenAwareUrine_left {
    width: 100%;
  }
  .RenAwareUrine .RenAwareUrine_right {
    width: 100%;
  }
  .RenAwareUrine .RenAwareUrine_left button {
    width: 100%;
  }
  .contactPopupLeft {
    height: auto;
  }
  .contactPopupRight {
    height: auto;
  }
  .modelcloseIcon {
    display: none;
  }
  .contactPopup {
    padding: 20px auto;
    height: auto;
  }
  .contactPopup .heading {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-second-dark-blue);
  }
  .contactPopup p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--color-black);
  }
  .modelcloseIconWhite{
    display: block;
  }
}
