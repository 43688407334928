.Footer {
    padding: 64px 120px;
    gap: 64px;
    min-height: 600px;
    background: var(--color-second-dark-blue);
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    height: auto;
}

.Footer .logo {
    width: 78px;
    height: 78px;
}

.Footer .logo img {
    height: 100%;
}

.Footer .social{
    width: 180px;
    display: flex;
    justify-content: space-between;
}

.Footer .detail {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
}

.Footer .detail .section h5 {
    margin: 0;
    font-weight: 300;
    font-size: 24px;
    line-height: 28.15px;
    letter-spacing: 0%;
}

.Footer .detail .section p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
}

.Footer .detail .section .email {
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
}

.Footer .detail .section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
}

.Footer .coptyRight {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
}

@media only screen and (max-width: 991px) {
    .Footer{
        padding: 56px 24px;
    }
}
