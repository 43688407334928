.PipelineContainer {
  background: var(--color-dark-blue);
}
.Pipeline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 72px 120px;
  gap: 16px;
  background: var(--color-white-blue);
  border-radius: 64px;
}

.Pipeline .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-black);
}

.Pipeline .pipelineHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  height: 40px;
  background: #e9eff0;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black);
}
.Pipeline .tableRow {
  height: 144px;
  background: var(--color-white-blue);
  /* border-radius: 16px; */
  border-top: 1px solid #CAC4D0;
  
}

.Pipeline .pipeLine_product {
    /* padding: 8px; */
}

.pipeLine_product .productImg {
  padding: 0px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.pipeLine_product .productImg img {
  border-radius: 4px;
  height: 90%;
  object-fit: cover;
}
.pipeLine_product .productName {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--color-black);
}
.pipeLine_product .description {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--color-black);
}
.pipeLine_product .product_detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.Pipeline .product_concept_validation {
  display: flex;
  align-items: center;
  gap: 8px;
}
.product_concept_validation .progressBar {
  height: 24px;
  border-radius: 16px;
  width: 100%;
}
.product_concept_validation .progress {
  background: #37618e;
  height: 24px !important;
  border-radius: 16px;
}

.Pipeline .product_research_partners {
    display: flex;
    flex-wrap: wrap;
    height: 144px;
    overflow: auto;
}

.product_research_partners img{
    width: 100%;
    max-width: 50%;
    object-fit: contain;
    border-radius: 8px;
    max-height: 100%;
    padding: 5px;
}

@media only screen and (max-width: 991px) {
    .PipelineContainer{
        display: none;
    }
}