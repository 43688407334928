.ScentawareContainer {
  gap: 16px;
  height: 900px;
  background: var(--color-white-blue);
}

.Scentaware {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding: 0px 120px;
  gap: 24px;
  height: 900px;
  background: url("../../assets/images/ScentawareBg.jpg");
  background-size: cover;
  border-radius: 64px 64px 0px 0px;
}

.Scentaware .detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  width: 533px;
  height: 332px;
}

.Scentaware .detail h2 {
  font-weight: 300;
  font-size: 45px;
  line-height: 52px;
  letter-spacing: -1px;
  color: var(--color-second-dark-blue);
  margin: 0;
}

.Scentaware .detail p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--color-second-dark-blue);
}

.Scentaware .detail .btnSection {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
}

.Scentaware .detail .btnSection .Scentaware-16 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 206px;
  height: 40px;
  background: #37618e;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  color: var(--color-white);
  border: none;
}

.Scentaware .detail .btnSection .Scentaware-8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 200px;
  height: 40px;
  background: var(--color-white-light-blue);
  border-radius: 100px;
  border: none;
  color: #001e2b;
}

@media only screen and (max-width: 991px) {
  .Scentaware {
    align-items: flex-end;
    justify-content: center;
    padding: 56px 24px;
    border-radius: 40px 40px 0px 0px;
  }
  .Scentaware .detail h2{
    font-size: 36px;
  }
  .Scentaware .detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 32px;
    background: rgba(248, 249, 255, 0.9);
    border-radius: 28px;
    z-index: 0;
    height: fit-content;
    max-width: 90%;
  }
}
