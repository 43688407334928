.ScentAwareSmellLoss {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 120px;
  gap: 24px;
  height: 600px;
  background: url("../../assets/images/scentaware-blue.png");
  background-size: cover;
  background-position: 80%;
  background-repeat: no-repeat;
}
.ScentAwareSmellLoss .text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 486px;
}
.ScentAwareSmellLoss .text-content .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-white);
}

.ScentAwareSmellLoss .text-content .bottom {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
}
.ScentAwareSmellLoss .text-content .bottom p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--color-white);
}

.ScentAwareSmellLoss .text-content .bottom button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 8px;
  height: 40px;
  background: var(--color-white-light-blue);
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #001e2b;
  border: none;
}
@media only screen and (max-width: 991px) {
  .ScentAwareSmellLoss {
    padding: 56px 24px;
    height: 856px;
  }
  .ScentAwareSmellLoss .text-content {
    width: 100%;
    height: 100%;
    justify-content: space-between;
  }
  .ScentAwareSmellLoss .text-content .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 24px;
    margin: 0 auto;
    background: rgba(3, 26, 64, 0.8);
    backdrop-filter: blur(4px);
    border-radius: 28px;
  }
}
