.header {
  background-color: var(--color-dark);
  height: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding);
  box-sizing: border-box;
  white-space: nowrap;
  position: sticky;
  top: 0;
  z-index: 999;
}
.MainLogoContainer {
  width: 136px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-white);
  font-weight: 500;
  font-size: 18px;
  line-height: 21.11px;
}

.header .menu ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  margin: 0 auto;
  height: 40px;
  list-style-type: none;
  color: var(--color-light-blue);
  font-family: Work Sans;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}
.header .menu ul a{
  color: var(--color-light-blue);
  text-decoration: none;
}
.rightContent {
  display: flex;
  gap: 24px;
}
.rightContent .requestDemo {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 161px;
  height: 40px;
  border: 1px solid #8d9199;
  border-radius: 100px;
  background-color: transparent;
  color: var(--color-light-blue);
}
.rightContent .login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  height: 40px;
  background: #37618e;
  border-radius: 100px;
  color: var(--color-white);
  border: none;
}

.header-responsive {
  position: absolute;
  top: 56px;
  margin: 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;
  height: 64px;
  background: #111418;
  border-radius: 12px;
  z-index: 1;
  width: calc(100% - 48px);
}

.responsive-sidebar{
  background-color: #111418 !important;
}
.responsive-sidebar .btn-close {
  color: #ff5733; /* Set your desired color */
}

.responsive-sidebar .menu ul{
  list-style-type: none;
  color: #A1C9FD;
  padding: 0;
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}
.responsive-sidebar .menu ul li{
  cursor: pointer;
}
.responsive-sidebar .menu ul a{
  color: var(--color-light-blue);
  text-decoration: none;
}

.userName::before {
  content: attr(data-userName);
}
@media only screen and (max-width: 1300px) {
  .header .menu ul{
    gap: 10px;
  }
  .header .rightContent{
    gap: 10px;
  }
  .userName::before {
    content: attr(data-userName);
    display: inline-block;
    width: 1ch;
    overflow: hidden;
  }
}