.MainBanner {
  display: flex;
  align-items: center;
  gap: 24px;
  height: 700px;
  position: relative;
  background-color: var(--color-blue);
  padding: 16px 120px;
}
.MainBanner video {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 0;
}
.MainBannerContent {
  /* padding-left: 120px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 486px;
  z-index: 1;
}

.MainBannerContent span {
  font-weight: 300;
  font-size: 45px;
  line-height: 52px;
  color: var(--color-white);
}
.MainBannerContent p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--color-white);
}

@media only screen and (max-width: 991px) {
  .MainBanner {
    padding: 56px 24px;
    height: 917px;
    gap: 16px;
    display: flex;
    flex-direction: column;
  }
  .MainBannerContent{
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding: 56px 0px;
    margin-top: 56px;
  }
  .MainBannerContent span{
    font-size: 36px;
  }
}
