.Products {
    display: flex;
    flex-direction: column;
    padding: 48px 120px;
    background: var(--color-white-blue);
}
@media only screen and (max-width: 991px) {
    .Products{
        padding: 56px 24px;
    }
}