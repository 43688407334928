.ParkinsonsDiseaseContainer {
  height: 500px;
  background: var(--color-dark-blue);
  position: relative;
}
.ParkinsonsDisease {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  isolation: isolate;
  height: 500px;
  background: url("../../assets/images/ParkinsonsDisease.png");
  background-position: center;
  background-size: cover;
  border-radius: 0px 0px 198px 0px;
}
.ParkinsonsDiseaseContainer .text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 24px;
  gap: 24px;
  width: 486px;
  position: absolute;
  top: 50%;
  right: 120px;
  transform: translateY(-50%);
}
.ParkinsonsDiseaseContainer .text-content .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-dark-blue);
}
.ParkinsonsDiseaseContainer .text-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--color-dark-blue);
  margin: 0;
}
@media only screen and (max-width: 991px) {
    .ParkinsonsDiseaseContainer{
        height: auto;
    }
    .ParkinsonsDisease{
        height: 291px;
        border-radius: 0px;
    }
    .ParkinsonsDiseaseContainer .text-content{
        position: static;
        background-color: var(--color-white);
        top: auto;
        right: auto;
        transform: none;
        width: auto;
        padding: 56px 24px;
        border-radius: 0px 0px 64px 0px;
    }
}
