.OurApproach {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding);
  gap: 24px;
  min-height: 350px;
  background: var(--color-dark-blue);
}
.OurApproach h3 {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: #d2e4ff;
}

.OurApproach_card {
  display: flex;
  align-items: center;
  gap: 16px;
}
.OurApproach_card img {
  width: 64px;
  height: 64px;
}
.OurApproach_card p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #d2e4ff;
  margin: 0;
}
@media only screen and (max-width: 991px) {
    .OurApproach{
        padding: 56px 24px;
    }
    .OurApproach_cards{
        gap: 40px;
    }
    .OurApproach_card{
        padding: 0px;
    }
}