.ProductContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px 0px;
    gap: 24px;
    border-radius: 64px 64px 0px 0px;
    
}
.ProductContainer.left{
    flex-direction: row-reverse;
}
.ProductContainer .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 60%;
}

.ProductContainer .img {
    width: 40%;
    height: 384px;
}

.ProductContainer .img img {
    width: 100%;
    height: 100%;
    border-radius: 48px;
    object-fit: cover;
}
.ProductContainer .content .title {
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: var(--color-dark-blue);
}

.ProductContainer .content .count{
    display: flex;
    font-weight: 300;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;
    gap: 20px;
    cursor: pointer;
    user-select: none;
}

.ProductContainer .content .price {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--color-dark-blue);
}

.btn-container{
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}
@media only screen and (max-width: 1300px) {
    .ProductContainer .content {
        width: 50%;
    }
    .ProductContainer .img {
        width: 50%;
    }
}
@media only screen and (max-width: 991px) {
    
    .ProductContainer{
        flex-direction: column-reverse;
        justify-content: flex-end;
    }
    .ProductContainer .content{
        width: 100%;
    }
    .ProductContainer .img{
        width: 100%;
    }
    .ProductContainer .img img{
        border-radius: 15px;
    }
    .btn-container{
        gap: 6px;
    }
    .btn-container button{
        padding: 10px;
        font-size: 14px;
    }
    .ProductContainer.left{
        flex-direction: column-reverse;
    }
}

@media only screen and (max-width: 768px) {
    .ProductMainContainer{
        padding: 0px;
    }
    .ProductContainer .img{
        max-height: 320px;
    }
}