.AnnulPlanContainer {
  background-color: #003259;
}
.AnnulPlan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 64px 120px;
  gap: 24px;
  isolation: isolate;
  height: 700px;
  background: var(--color-white-light-blue);
  position: relative;
  border-radius: 0px 96px 0px 0px;
}
.AnnulPlan .bgImg {
  position: absolute;
  /* background-image: url("../../assets/images/mobileTablet.png"); */
  width: 600px;
  height: 470px;
  background-size: cover;
  background-position: center;
  right: 0;
  top: 0;
  
}
.AnnulPlan .text-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  width: 693px;
  z-index: 1;
}
.AnnulPlan .text-content .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-black);
}
.AnnulPlan .text-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--color-black);
}
.AnnulPlan button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: 40px;
  background: #37618e;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: var(--color-white);
  border: none;
  padding: 10px 24px;
}

.AnnulPlan .planCards {
  display: flex;
  flex-direction: row;
  
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  z-index: 1;
}
.AnnulPlan .planCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  background: var(--color-white);
  border-radius: 28px;
  max-width: 400px;
}
.AnnulPlan .planCard .title {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #001e2b;
}
.AnnulPlan .planCard ul li {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #001e2b;
}
@media only screen and (max-width: 991px) {
    .AnnulPlan{
        height: auto;
        padding: 56px 24px;
    }
    .AnnulPlan .text-content{
        width: auto;
    }
    .AnnulPlan .bgImg{
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 350px;
        object-fit: cover;
    }
    .AnnulPlan .planCards{
        width: 100%;
        flex-wrap: wrap;
    }
    .AnnulPlan .planCard{
        max-width: 100%;
        min-width: fit-content;
        width: 100%;
    }

}

@media only screen and (max-width: 768px) {
    .AnnulPlan button {
        width: 100%;
    }
}