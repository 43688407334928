.ResearchersBanner {
  display: flex;
  align-items: center;
  height: 500px;
  position: relative;
  background-color: var(--color-blue);
  padding: 40px 120px;
  gap: 96px;
}
.ResearchersBanner video {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 0;
}
.ResearchersBanner .text-content {
  width: 690px;
  height: 196px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.ResearchersBanner h1 {
  font-weight: 300;
  font-size: 45px;
  line-height: 52px;
  letter-spacing: -1px;
  color: #d2e4ff;
  margin: 0;
}
.ResearchersBanner p {
  width: 588px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #d2e4ff;
}

@media only screen and (max-width: 991px) {
  .ResearchersBanner {
    height: 800px;
    padding: 56px 24px;
  }
  .ResearchersBanner .text-content h1 {
    width: 100%;
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
  }
  .ResearchersBanner .text-content p {
    width: 100%;
  }
}
