.PreBuiltContainer {
  background-color: #f1f2f6;
}
.PreBuilt {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 120px;
  gap: 24px;
  height: 710px;
  background: var(--color-white-blue);
  border-radius: 198px 0px 0px 0px;
}
.PreBuilt .text-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 96px;
}
.PreBuilt .text-content-insite{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.PreBuilt .text-content ul{
    list-style-type: decimal;
}
.PreBuilt img {
  /* width: 100%; */
  max-height: 100%;
  object-fit: cover;
}
.PreBuilt .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: #001e2b;
}
.PreBuilt p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #001e2b;
  margin: 0;
}
.PreBuilt .imageSection{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
@media only screen and (max-width: 991px) {
    .PreBuilt{
        padding: 56px 24px;
        height: auto;
        border-radius: 64px 0px 0px 0px;
    }
    .PreBuilt .row{
        flex-direction: column-reverse;
        gap: 48px;
    }
    .PreBuilt .heading {
        font-size: 28px;
    }
    .PreBuilt img {
      width: 100%;
    }
    .PreBuilt p {
        font-size: 16px;
    }
    .PreBuilt .text-content{
        gap: 48px;
    }
}
