.IntelliVista {
  padding: 0px 120px 0px 0px;
  height: 500px;
  background: url("../../assets/images/bg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.IntelliVista .text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px 120px;
  gap: 24px;
  width: 50%;
  height: 500px;
  background: rgba(3, 26, 64, 0.8);
  backdrop-filter: blur(4px);
}
.IntelliVista .text-content h2 {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: #d2e4ff;
}
.IntelliVista .text-content p {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #d2e4ff;
}
.IntelliVista .text-content button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 242px;
  height: 40px;
  background: #a1c9fd;
  border-radius: 100px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #003259;
}

@media only screen and (max-width: 1400px) {
  .IntelliVista .text-content {
    padding: 48px 64px;
  }
}
@media only screen and (max-width: 1200px) {
  .IntelliVista .text-content {
    padding: 24px;
  }
}
@media only screen and (max-width: 991px) {
  .IntelliVista {
    padding: 56px 24px;
    height: 800px;
    display: flex;
    align-items: end;
  }
  .IntelliVista .text-content {
    padding: 24px;
    border-radius: 28px;
    width: 100%;
    height: auto;
  }
}
