.PathOfSmell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 120px;
    gap: 48px;
    min-height: 600px;
    background: var(--color-dark-blue);
}

.PathOfSmellBanner {
    width: 682px;
    height: 600px;
    background: url('../../assets/images/pathOfSmell.png');
    mix-blend-mode: lighten;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
}

.PathOfSmellContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 48px;
}

.PathOfSmellContent h2 {
    width: 590px;
    /* height: 80px; */
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1px;
    color: #E1E2E8;
    margin: 0;
}

.PathOfSmellTypesOfSmell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 590px;
    /* height: 224px; */
}

.PathOfSmellContent h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #E1E2E8;
    margin: 0;
}

.PathOfSmellContent p {
    width: 590px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #E1E2E8;
    margin: 0;
}

.PathOfSmellTypesOfSmell .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 590px;
}

.PathOfSmellTypesOfSmell .contentInside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px;
    gap: 8px;
    width: 190px;
    height: 76px;
    background: #003259;
    border-radius: 8px;
}

.PathOfSmellTypesOfSmell .contentInside .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #DEE3E5;
}

.PathOfSmellTypesOfSmell .contentInside .p {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.4px;
    color: #DEE3E5;
    text-wrap: wrap;
    margin: 0;
    width: auto;
}

@media only screen and (max-width: 991px) {
    .PathOfSmellBanner{
        display: none;
    }
    .PathOfSmellContent{
        width: 100%;
    }
    .PathOfSmell{
        padding: 56px 24px;
    }
    .PathOfSmellTypesOfSmell{
        width: 100%;
        position: relative;
    }
    .PathOfSmellTypesOfSmell .content{
        width: 100%;
    }
    .PathOfSmellTypesOfSmell .contentInside{
        width: 50%;
    }
}

@media only screen and (max-width: 768px) {
    .PathOfSmellTypesOfSmell .content{
        flex-wrap: wrap;
    }
    .PathOfSmellTypesOfSmell .contentInside{
        width: 100%;
    }
    .PathOfSmellContent{
        height: 100%;
    }
    .PathOfSmellContent p {
        width: 100%;
    }
    .PathOfSmellContent h2{
        width: 100%;
    }
}