.TestYourSenseContainer{
    min-height: 500px;
    position: relative;
    width: 100%;
    background-color: var(--color-dark-blue);
}

.TestYourSense {
    position: relative;
    padding: 0px 120px;
    gap: 24px;
    width: 100%;
    min-height: 500px;
    background: var(--color-white-blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0px 0px 198px 0px;
}

.TestYourSense h2 {
    font-weight: 300;
    font-size: 45px;
    line-height: 52px;
    letter-spacing: -1px;
    color: var(--color-second-dark-blue);
    margin: 0;
    width: 533px;
}

.TestYourSense .steps {
    display: flex;
    justify-content: space-between;
    gap: 24px;
}

.TestYourSense .steps .stepCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
}

.TestYourSense .steps .stepCard small {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #5BC0EB;
    margin: 0;
    margin-bottom: 16px;
}

.TestYourSense .steps .stepCard h5 {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0px;
    color: var(--color-second-dark-blue);
    margin: 0;
}

.TestYourSense .steps .stepCard p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    color: var(--color-second-dark-blue);
}

.TestYourSense .steps .arrowStep{
    display: flex;
    justify-content: center;
    }

.TestYourSense .steps .arrowStep img{
    width: 24px;
}

@media only screen and (max-width: 991px) {
    .TestYourSense{
        padding: 56px 24px;
    }
    .TestYourSense h2{
        font-size: 36px;
        width: 100%;
    }
    .TestYourSense .steps{
        flex-direction: column !important;
        align-items: center;
    }
    .TestYourSense .stepCard{
        width: 300px;
    }
    .TestYourSense .arrowStep{
        transform: rotate(90deg);
        width: 16px;
        height: 16px;
    }
}
@media only screen and (max-width: 768px) {
    .TestYourSense .stepCard{
        width: 100%;
    }
    .TestYourSense{
        border-radius: 0px 0px 48px 0px;
    }
}