:root {
    --color-dark: #111418;
    --color-black: #000000;
    --color-white: #FFFFFF;
    --color-white-light-blue: #c2e8ff;
    --color-light-blue: #A1C9FD;
    --color-second-light-blue: #8ecff2;
    --color-white-blue: #E1E2E8;
    --color-blue: #041940;
    --color-bg-light-blue: #1B4975;
    --padding: 24px 120px;
    --padding-large: 56px 120px;
    --color-white-blue: #f8f9ff;
    --color-dark-blue: #001C37;
    --color-second-dark-blue: #031A40;
}
/* *{
    border: 1px solid red;
} */
 a{
    text-decoration: none !important;
 }

.gap-20 {
    gap: 20px;
}

.gap-16 {
    gap: 16px;
}
.gap-8 {
    gap: 8px;
}
.gap-10 {
    gap: 10px;
}
.gap-24{
    gap: 24px;
}
.gap-48{
    gap: 48px;
}

.text-dark-small-bold {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    color: var(--color-dark-blue);
}

.text-dark-small-thin {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    color: var(--color-dark-blue);
}

.btn-rounded-blue {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    height: 40px;
    background: #37618E;
    border-radius: 100px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: var(--color-white);
    border: none;
    gap: 8px;
    cursor: pointer;
}
.btn-rounded-blue-outline{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    height: 40px;
    background: transparent;
    border-radius: 100px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #37618E;
    border: none;
    border:  1px solid #37618E;
    gap: 8px;
    cursor: pointer;
}

.cursor-pointer{
    cursor: pointer;
}
.dark-close-btn{
    border: none;
    padding: 0px;
    width: 30px;
    height: 30px;
    /* border: 1px solid white; */
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 30px;
}

@media only screen and (max-width: 1200px) {
    .btn-rounded-blue-outline.download-btn{
        padding: 0px;
    }
    .btn-rounded-blue-outline.download-btn span{
        display: none;
    }
    .btn-rounded-blue-outline.download-btn::before{
        content: '';
        background-image: url('./assets/images/download.svg');
        height: 40px;
        width: 40px;
        background-repeat: no-repeat;
        padding: 0px;
        background-position: center;
    }
}
@media only screen and (max-width: 1300px) {
    :root{
        --padding: 24px 80px;
        --padding-large: 56px 80px;
    }
}
@media only screen and (max-width: 1091px) {
    :root{
        --padding: 24px 50px;
        --padding-large: 56px 50px;
    }
}
@media only screen and (max-width: 991px) {
    :root{
        --padding: 24px;
        --padding-large: 56px 24px;
    }
    .user_profile{
        margin-top: 150px;
    }
    .IntelliVista_banner{
        padding-top: 120px !important;
    }
}