.staticHeader {
    background-color: var(--color-dark);
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding);
    box-sizing: border-box;
    white-space: nowrap;
    /* position: sticky;
    top: 0; */
    z-index: 999;
  }
  @media only screen and (max-width: 991px) {

  }