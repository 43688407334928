.RenAware {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 120px;
  gap: 24px;
  isolation: isolate;
  height: 600px;
  background: var(--color-second-light-blue);
}
.RenAware .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: #001e2b;
}
.RenAware p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #001e2b;
}
.RenAware button{
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 8px;
  height: 40px;
  background: var(--color-white-light-blue);
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #001e2b;
  border: none;
}
.RenAware img{
  max-width: 100%;
  max-height: 100%;
}
@media only screen and (max-width: 991px) {
    .RenAware{
        height: auto;
        padding: 56px 24px;
    }
    .RenAware img{
      width: 100%;
    }
}
