.ScentAwareProductsContainer {
  background-color: var(--color-white-light-blue);
}
.ScentAwareProducts {
  border-radius: 96px;
  overflow: hidden;
  background-color: var(--color-white-blue);
  padding: var(--padding-large);
}
.ScentAwareProducts .topDetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 64px;
  gap: 16px;
  background-color: var(--color-white-blue);
}

.ScentAwareProducts .topDetail .heading {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-second-dark-blue);
}

.ScentAwareProducts .topDetail p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--color-black);
}

@media only screen and (max-width: 991px) {
    .ScentAwareProducts {
        border-radius: 24px;
        padding: 24px;
    }
    .ScentAwareProducts .topDetail{
        display: none;
    }
}