.ProductVideo{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 64px 120px;
    gap: 24px;
    /* height: 700px; */
    background: var(--color-white);
    box-sizing: border-box;
    max-height: 85vh;
}
.ProductVideo iframe{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 32px;
    max-height: 80vh;
    min-height: 570px;
}

@media only screen and (max-width: 991px) {
    .ProductVideo{
        padding: 0px 24px;
        background-color: var(--color-white-blue);
        
    }
    .ProductVideo iframe{
        border-radius: 12px;
        height: 220px;
        min-height: 220px;
    }
}

