.IndividualsUsedContainer {
    background-color: var(--color-white-blue);
    height: 700px;
    position: relative;
    width: 100%;
}

.IndividualsUsed {
    display: flex;
    position: absolute;
    flex-direction: row;
    align-items: center;
    padding: 0px 120px;
    gap: 24px;
    height: 700px;
    width: 100%;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background: url('../../assets/images/individualsUsed.jpeg');
    border-radius: 0px 64px 0px 0px;
}

.IndividualsUsedContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 48px 0px 0px;
    gap: 24px;
    width: 486px;
    height: 420px;
}

.IndividualsUsedContent .title, .IndividualsUsedContentBottom .title {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1px;
    color: #37618E;
}

.IndividualsUsedContent p, .IndividualsUsedContentBottom p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #37618E;

}

.IndividualsUsedContent button, .IndividualsUsedContentBottom button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 278px;
    height: 40px;
    background: var(--color-white-light-blue);
    border-radius: 100px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    border: none;
    color: #001E2B !important;
}
.IndividualsUsedContentBottom{
    display: none;
    flex-direction: column;
    padding: 32px 24px;
}
@media only screen and (max-width: 768px) {
    .IndividualsUsedContainer{
        height: auto;
    }
    .IndividualsUsed{
        height: 350px;
        border-radius: 0px;
        position: relative;
    }
    .IndividualsUsedContent{
        display: none;
    }
    .IndividualsUsedContentBottom{
        display: flex;
        gap: 24px;
    }
    .IndividualsUsedContentBottom button{
        width: 100%;
    }
}