.InidividualsBanner {
    position: relative;
    min-height: 650px;
    background: var(--color-dark-blue);
}

.IndividualsBannerCorner{
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 48px 120px;
    gap: 24px;
    position: absolute;
    min-height: 650px;
    width: 100%;
    background: url('../../assets/images/individualsBanner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0px 0px 108px 0px;
}

.individualsBannerInsideText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    gap: 24px;
    max-width: 588px;
    min-height: 248px;
    background: rgba(0, 28, 55, 0.7);
    backdrop-filter: blur(4px);
    border-radius: 28px;
    box-sizing: border-box;
}

.individualsBannerInsideText h1 {
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 45px;
    line-height: 52px;
    letter-spacing: -1px;
    color: var(--color-white);
}

.individualsBannerInsideText p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--color-white);
}

.individualsBannerInsideCardContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;
    flex-wrap: wrap;
}

.individualsBannerInsideCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 16px;
    width: 282px;
    height: 96px;
    background: rgba(0, 28, 55, 0.8);
    border-radius: 16px;
    box-sizing: border-box;
}

.individualsBannerInsideCard .icon {
    padding: 12px;
    width: 48px;
    height: 48px;
    background: #003259;
    border-radius: 12px;
    box-sizing: border-box;
}

.individualsBannerInsideCard p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--color-white);
}

@media only screen and (max-width: 991px) {
    .InidividualsBanner{
        height: 1092px;
    }
    .IndividualsBannerCorner{
        height: 1092px;
        padding: 56px 24px;
    }
    .individualsBannerInsideCardContainer{
        flex-direction: column;
    }

}
@media only screen and (max-width: 768px) {
    .individualsBannerInsideCard{
        width: 100%;
        border-radius: 12px;
    }
    .individualsBannerInsideText h1{
        font-size: 36px;
    }
    .IndividualsBannerCorner{
        border-radius: 0px;
    }
    .individualsBannerInsideText{
        border-radius: 12px;
    }
}