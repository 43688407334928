.Harness {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 120px;
  gap: 48px;
  height: 400px;
  background: var(--color-white-blue);
}

.Harness h4 {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: #191c20;
}

.Harness .icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 56px;
  height: 56px;
  background: #37618e;
  border-radius: 16px;
}
.HarnessCard {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.HarnessCard p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #191c20;
}

@media only screen and (max-width: 991px) {
  .Harness{
    padding: 64px 24px;
    height: auto;
  }
  
  .HarnessCard{
    padding: 0;
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (max-width: 768px) {
  .HarnessCards{
    gap: 48px;
  }
}
