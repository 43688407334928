.AlzheimersDiseaseContainer {
  height: 724px;
  background-color: var(--color-white);
}
.AlzheimersDisease {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-large);
  gap: 24px;
  isolation: isolate;
  height: 724px;
  background: var(--color-dark-blue);
  border-radius: 198px 0px 0px 0px;
}
.AlzheimersDisease_left {
  height: 100%;
}
.AlzheimersDisease_left img {
  /* width: 100%; */
  height: 100%;
}
.AlzheimersDisease_right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}
.AlzheimersDisease_right .heading {
  width: 390px;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-white);
}
.AlzheimersDisease_right p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--color-white);
  margin: 0;
}

.AlzheimersDisease_right ul{
    color: var(--color-white);
}
@media only screen and (max-width: 991px) {
    .AlzheimersDisease{
        padding: 56px 24px;
        border-radius: 64px 0px 0px 0px;
    }
    .AlzheimersDiseaseContainer{
        height: auto;
    }
    .AlzheimersDisease_left img {
        width: 100%;
    }
    .AlzheimersDisease{
        height: auto;
    }
    .AlzheimersDiseaseRow{
        gap: 48px;
    }
    .AlzheimersDisease_right .heading {
      font-size: 28px;
    }
}
