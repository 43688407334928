.SmellLoss {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 64px 120px 0px;
    gap: 48px;
    background: var(--color-white-blue);
    box-sizing: border-box;
}

.SmellLoss .topDetail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
}

.SmellLoss .topDetail small {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
}

.SmellLoss .topDetail h2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    font-weight: 300;
    font-size: 45px;
    line-height: 52px;
    letter-spacing: -1px;
    margin: 0;

}

.SmellLoss .topDetail p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #191C20;
    margin: 0;
}

.SmellLoss .iconicContent {
    display: flex;
    /* gap: 24px; */
    width: 100%;
}

.SmellLoss .iconicContent .iconicContentCard .detail {
    gap: 4px;
    display: flex;
    flex-direction: column;
}

.SmellLoss .iconicContent .iconicContentCard {
    display: flex;
    gap: 16px;
    padding-right: 16px !important;
    padding-bottom: 28px !important;
    padding-top: 28px;
}

.SmellLoss .iconicContent .iconicContentCard p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #191C20;
    margin: 0;
}

.smellLossDetail {
    width: 755px;
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.smellLossDetail span {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1px;
}

.smellLossDetail p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.SmellLoss .bottomContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    gap: 48px;
    height: fit-content;
    overflow-x: clip;
    width: 100%;
    min-height: 400px;
}

.SmellLoss .smellLossDetailImg {
    width: 420px;
    height: 100%;
    min-height: 400px;
    flex: none;
    order: 0;
    flex-grow: 0;
    position: relative;
}
.SmellLoss .smellLossDetailImg .img{
    width: 420px;
    height: 520px;
    background: url('../../assets/images/sadPerson.png');
    background-size: cover;
    position: absolute;
    bottom: 0;
}



@media only screen and (max-width: 991px) {
    .SmellLoss{
        padding: 56px 24px 0px;
    }
}

@media only screen and (max-width: 768px) {
    .SmellLoss .iconicContent{
        /* gap: 40px; */
    }
    .SmellLoss{
        padding: 56px 24px 0px;
    }
    .bottomContent{
        flex-direction: column !important;
    }
    .smellLossDetail{
        width: 100%;
    }
    .smellLossDetailImg{
        width: 100% !important;
        display: flex;
        justify-content: center;
    }
    .smellLossDetailImg .img{
        position: relative !important;
    }
}