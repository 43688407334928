.MultiModal {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 120px;
    gap: 24px;
    isolation: isolate;
    min-height: 800px;
    background: var(--color-blue);
}

.MultiModalImgContainer {
    position: relative;
    height: 800px;
}

.MultiModalImg {
    position: absolute;
    width: 100%;
    height: 700px;
    background: url('../../assets/images/multimodal.png');
    background-size: cover;
    background-blend-mode: difference;
    background-position: center;
    bottom: 0;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.MultiModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 0px;
    gap: 48px;
    height: 100%;
}

.MultiModalContent span {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    /* letter-spacing: -1px; */
    color: var(--color-white-blue);
}

.MultiModalContent p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--color-white);
    margin: 0;
}

.MultiModalContentCardsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

.MultiModalContentCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.MultiModalContentCard .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 56px;
    height: 56px;
    background: #A1C9FD;
    border-radius: 16px;
}

.MultiModalContentCard .detail h6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    color: var(--color-white-blue);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin: 0;
}

.MultiModalContentCard .detail p{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--color-white-blue);
    margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
    .MultiModal{
        height: auto;
        padding: 56px 24px;
    }
}
@media only screen and (max-width: 768px) {
    .MultiModalContentCardsContainer{
        grid-template-columns: 1fr;
    }
}