.ComprehensiveContainer {
  background: #f1f2f6;
}
.Comprehensive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 64px 120px;
  gap: 48px;
  background: var(--color-white-blue);
  border-radius: 0px 0px 198px 0px;
}
.Comprehensive .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-second-dark-blue);
}
.ComprehensiveCard_box {
  width: 24px;
  height: 24px;
  background: #e88280;
  border-radius: 8px;
}
.Comprehensive .ComprehensiveCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
}
.Comprehensive .ComprehensiveCard .detail {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.Comprehensive .ComprehensiveCard .title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--color-second-dark-blue);
}
.Comprehensive .ComprehensiveCard p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;

  color: var(--color-second-dark-blue);
}

.accordionHeader {
  display: flex;
  gap: 16px;
}

.Comprehensive .accordion {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.Comprehensive .accordion-button {
  padding: 0px;
}
.Comprehensive .accordion-item {
  border: none;
}
.Comprehensive .accordion-button:not(.collapsed) {
  background-color: var(--color-white);
}
.Comprehensive .accordion-button:focus {
  box-shadow: none;
}
.Comprehensive .accordionHeader {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--color-second-dark-blue);
}

.Comprehensive_bottomContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: var(--padding);
  background: #f1f2f6;
  height: 300px;
}
.Comprehensive_bottomContent .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-dark-blue);
}
.Comprehensive_bottomContent p {
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #001e2b;
}
@media only screen and (max-width: 991px) {
  .Comprehensive {
    height: auto;
    padding: 56px 24px;
    background-color: var(--color-white);
    border-radius: 0px 0px 64px 0px;
  }
  .Comprehensive .heading {
    font-size: 24px;
  }
  .Comprehensive_bottomContent {
    padding: 56px 24px;
    height: auto;
  }
  .Comprehensive_bottomContent .row{
    gap: 48px;
  }
  .Comprehensive_bottomContent .heading{
    font-size: 28px;
  }
  .Comprehensive_bottomContent p{
    font-size: 16px;
  }
}
