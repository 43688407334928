.Revolutionizing {
    padding: 48px 120px;
    gap: 24px;
    min-height: 500px;
    background: var(--color-white-blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Revolutionizing h2 {
    font-weight: 300;
    font-size: 45px;
    line-height: 52px;
    letter-spacing: -1px;
    color: var(--color-second-dark-blue);
    margin: 0;
    width: 533px;
}

.Revolutionizing .steps {
    display: flex;
    justify-content: space-between;
    gap: 24px;
}

.Revolutionizing .steps .stepCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    gap: 16px;
}

.Revolutionizing .steps .stepCard small {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #5BC0EB;
}

.Revolutionizing .steps .stepCard h5 {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0px;
    color: var(--color-second-dark-blue);
    margin: 0;
}

.Revolutionizing .steps .stepCard p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    color: var(--color-second-dark-blue);
}

.Revolutionizing .steps .arrowStep{
    display: flex;
    justify-content: center;
    }

.Revolutionizing .steps .arrowStep img{
    width: 24px;
}

@media only screen and (max-width: 991px) {
    .Revolutionizing{
        padding: 56px 24px;
    }
    .Revolutionizing h2{
        font-size: 36px;
        width: 100%;
    }
    .Revolutionizing .steps{
        flex-direction: column !important;
        align-items: center;
    }
    .Revolutionizing .stepCard{
        width: 300px;
    }
    .Revolutionizing .arrowStep{
        transform: rotate(90deg);
        width: 16px;
        height: 16px;
    }
}
@media only screen and (max-width: 768px) {
    .Revolutionizing .stepCard{
        width: 100%;
    }
}