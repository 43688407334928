.OngoingStudiesContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    height: 500px;
    background: var(--color-blue);
    position: relative;
}

.OngoingStudies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    isolation: isolate;
    width: 100%;
    height: 500px;
    background: var(--color-white-blue);
    border-radius: 64px 64px 0px 0px;
    padding: 48px 120px;
}

.OngoingStudies .detail {
    gap: 16px;
    display: flex;
    flex-direction: column;
}

.OngoingStudies .detail span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1px;
    color: var(--color-black);
}

.OngoingStudies .detail p {
    width: 432px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--color-black);
    margin: 0;
}

.OngoingStudies .rightMap {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0.29px;
    top: 0px;
    background: url('../../assets/images/map.png');
    flex: none;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    order: 3;
    flex-grow: 0;
    z-index: 3;
}

@media only screen and (max-width: 991px) {
    .OngoingStudies{
        padding: 56px 24px;
        border-radius: 28px 28px 0px 0px;
    }
    .OngoingStudies .detail p{
        width: 100%;
    }
    .OngoingStudies .detail span{
        margin-bottom: 32px;
    }
    .OngoingStudies{
        height: auto;
    }
    .OngoingStudiesContainer{
        height: auto;
    }
}
  