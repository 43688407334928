.SmellLossIndividualsContainer{
    position: relative;
    min-height: 852px;
    background-color: var(--color-dark-blue);
}
.SmellLossIndividuals {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 64px 120px;
    gap: 24px;
    isolation: isolate;
    min-height: 852px;
    background: var(--color-white-blue);
    border-radius: 0px 109px 0px 0px;
}

.SmellLossIndividuals .topDetail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
}

.SmellLossIndividuals .topDetail small {
    width: 385px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1px;
    color: var(--color-black);
}

.SmellLossIndividuals .topDetail h2 {
    margin: 0;
    height: 48px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #1D405C;

}

.SmellLossIndividuals .topDetail p {
    height: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #191C20;
    margin: 0;
}

.SmellLossIndividuals .iconicContent {
    width: 100%;
    display: flex;
    
}

.SmellLossIndividuals .iconicContent .iconicContentCard .detail {
    gap: 4px;
    display: flex;
    flex-direction: column;
}

.SmellLossIndividuals .iconicContent .iconicContentCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0px;
    gap: 16px;
    /* height: 180px; */
}

.SmellLossIndividuals .iconicContent .iconicContentCard p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #191C20;
    margin: 0;
    padding-right: 10px;
}

.SmellLossIndividualsDetail {
    width: 755px;
    height: 136px;
    gap: 24px;

}

.SmellLossIndividualsDetail span {
    height: 40px;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1px;
}

.SmellLossIndividualsDetail p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    width: 644px;
}

.SmellLossIndividuals .bottomContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    gap: 48px;
    height: 400px;
    overflow-x: clip;
    width: 100%;
}

.SmellLossIndividuals .SmellLossIndividualsDetailImg {
    width: 420px;
    height: 100%;
    flex: none;
    order: 0;
    flex-grow: 0;
    position: relative;
}

.SmellLossIndividuals .SmellLossIndividualsDetailImg .img {
    width: 420px;
    height: 520px;
    background: url('../../assets/images/sadPerson.png');
    background-size: cover;
    position: absolute;
    bottom: 0;
}


@media only screen and (max-width: 991px) {
    .SmellLossIndividuals{
        padding: 56px 24px;
        position: relative;
    }
    .SmellLossIndividuals .iconicContent .iconicContentCard{
        flex-direction: row;
    }
    .SmellLossIndividuals .topDetail{
        width: 100%;
    }
    .SmellLossIndividuals .topDetail small {
        font-size: 28px;
        width: auto;
    }
    .SmellLossIndividuals{
        border-radius: 0px 48px 0px 0px;
    }
}