.Contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 120px;
  gap: 48px;
  height: 600px;
  background: var(--color-white-blue);
}
.Contact_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 48px;
}
.Contact .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: var(--color-black);
}
.Contact_left .details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
}
.Contact_left .detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 48px;
}
.Contact .detail span {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  color: var(--color-black);
}
.Contact img {
  width: 48px;
  height: 48px;
}

.Contact_right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  width: 384px;
}
.Contact_right input,
.Contact_right textarea {
  display: flex;
  margin: 0px;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px 8px 16px;
  height: 56px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid var(--color-second-dark-blue);
  background-color: transparent;
}
.Contact_right input:focus,
.Contact_right textarea:focus {
  outline: none;
}
.Contact_right textarea {
  height: 140px;
}

.Contact_right button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: 40px;
  background: #37618e;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: var(--color-white);
  border: none;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .Contact {
    padding: 56px 24px;
    flex-wrap: wrap;
    height: auto;
  }
  .Contact_left {
    gap: 24px;
  }
  .Contact_right {
    width: 100%;
  }
  .Contact_left .detail {
    gap: 24px;
  }
  .Contact .heading {
    font-weight: 300;
    font-size: 24px;
  }
  .Contact .detail span {
    font-weight: 400;
    font-size: 14px;
  }
}
